html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #222;
  height: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.App-header h1 {
  font-size: 16px;
  font-weight: 200;
}

.App-logo {
  height: 60%;
  width: auto;
}

.App-main {
  position: relative;
  width: 93vw;
  /* height: calc(75vw * 0.6); */
  height: calc(65vw * 0.6);
  margin: 10px auto;
  /* border: 1px solid lightblue; */
  /* padding: 0px 30px 0 50px; */
  display: flex;
  margin-left: 4vw;
}

.App-control-container
{
  position: absolute;
  height: auto;
  width: 75%;
  left: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  bottom: 10vh;
  z-index: 1;
}

.App-control-container.hidden {
  display: none;
}

.App-control-container .ots-video-control
{
  width: 50px;
  height: 50px;
  margin: 20px 5px !important;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-color: #434345;
  background-repeat: no-repeat;
  cursor: pointer;
}

.App-control-container .ots-video-control.audio {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

.App-control-container .ots-video-control.audio:hover,
.App-control-container .ots-video-control.audio.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.App-control-container .ots-video-control.video {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.App-control-container .ots-video-control.video.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.App-control-container-dup
{
  position: absolute;
  height: auto;
  width: 75%;
  left: 0;
  display: flex;
  justify-content: center;
  bottom: 10vh;
}
.ots-dup-circle
{
  width: 50px;
  height: 50px;
  margin: 20px 5px;
  border-radius: 50%;
  background-color: #198000;
  z-index: -1;
  border: 2px solid white;
}
.ots-dup-circle.ots-dup-circle-active
{
  z-index: 1;
  display: none;
  background-image: url("../image/icons/iconmessage.png");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.App-video-container {
  position: relative;
  /* width: 73%; */
  width: 100%;
  height: 100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* border-radius: 20px; */
  overflow: hidden;
}

.App-mask {
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  background: rgba(27, 134, 144, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-mask .react-spinner {
  position: absolute;
}

.App-mask .message {
  font-weight: 200;
}

.App-mask .message.with-spinner {
  position: absolute;
  top: 55%;
}

.App-mask .message.button {
  border: 1px solid white;
  padding: 20px 40px;
  border-radius: 6px;
}

.App-video-container .video-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.App-video-container .video-container.small {
  position: absolute;
  /* top: 20px; */
  /* right: 20px; */
  top: 55px;
  left: 20px;
  width: 160px;
  height: 96px;
  border: 1px solid #fcba00;
  z-index: 2;
}

.App-video-container .video-container.small.left {
  left: 20px;
  border: 1px solid #00fcc2;
}

.App-video-container .video-container.hidden {
  display: none;
}

.App-video-container .video-container.active-2 .OT_subscriber {
  width: 50%;
}

.App-video-container .video-container.active-3 .OT_subscriber {
  width: calc(100% / 3) !important;
}

.App-video-container .video-container.active-4 {
  flex-wrap: wrap;
}

.App-video-container .video-container.active-4 .OT_subscriber {
  width: 50% !important;
  height: 50% !important;
}
.video-chat-container {
  /* width: 25%;
  position: relative; */
  width: 370px;
  position: absolute;
  right: 15px;
  bottom: 10vh;
  height: 78vh;
  z-index: 1;
}
.ots-text-chat {
  margin-top: 0;
  border: 0;
  position: absolute;
  bottom: 0;
  top: 100px;
  width: 100%;
}
.video-chat-container .ots-text-chat .ots-messages-holder {
  /* height: calc(80vh - 110px); */
  height: 62vh;
}
.ots-text-chat .ots-messages-holder .ots-message-item .ots-user-name-initial {
  overflow: hidden;
  padding: 0 3px;
}
.ots-video-control.end-call {
  background-color: #ff0018;
  background-image: url("../image/media/stop.png");
}
.ots-video-control.text-chat {
  background-color: green;
}
.ots-video-control.video,
.ots-video-control.audio {
  background-color: #0d98da;
}
.ots-text-chat .ots-messages-holder .ots-message-item .ots-item-text {
  float: left;
  margin-left: 60px;
  min-height: 30px;
}
.ots-text-chat .ots-messages-holder.has-alert {
  padding-top: 0;
}
.ots-text-chat .ots-messages-holder .ots-message-item {
  padding: 0;
  margin: 20px 10px;
}
.ots-text-chat
  .ots-messages-holder
  .ots-message-item.ots-message-sent
  .ots-item-text {
  margin-right: 60px;
  min-height: 30px;
}
.ots-icon-check {
  background-image: url("../image/media/sent-white.png");
  background-size: 20px;
}
.ots-text-chat .ots-send-message-box .ots-character-count {
  display: none;
}
.ots-text-chat .ots-send-message-box {
  border-radius: 30px;
  height: auto;
  border: 1px solid #c6c6c6;
  margin: 0 auto;
  width: 98%;
  position: absolute;
  bottom: 0;
  background-color: #fff;
}
.ots-text-chat .ots-send-message-box button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  right: 5px;
  top: 7px;
  background-color: #259de8;
}
.ots-text-chat .ots-send-message-box input[type="text"] {
  padding: 10px;
  padding-left: 10px;
}
.App-chat-attach {
  position: absolute;
  right: 38px;
  bottom: 6px;
  z-index: 1;
  /* display: none; */
}
.App-chat-attach .messageFileUpload {
  background: transparent url("../image/media/attachgrey.png") no-repeat scroll
    0 0 !important;
  width: 20px;
}
.App-chat-attach .messageloader {
  background: transparent url("../image/media/loading.png") no-repeat scroll 0 0 !important;
  -webkit-animation: spin 2s linear;
  animation: spin 2s linear;
}

.text-chat-container {
  width: 100%;
  right: 0;
  bottom: 0;
}
.text-chat-container .ots-text-chat .ots-send-message-box {
  padding-left: 30px;
  margin-left: 1%;
  width: 94%;
}
.text-chat-container .App-chat-attach {
  left: 16px;
  right: auto;
}
.text-chat-main {
  display: grid;
  width: 100%;
  margin-left: 0;
  border: 0;
  height: 70vh;
}
.text-chat-main .ots-text-chat .ots-messages-holder {
  overflow-y: auto;
  height: 64vh;
}
.text-chat-container .ots-text-chat {
  /* top: auto; */
  top: 0;
}
.text-chat-container
  .ots-text-chat
  .ots-messages-holder
  .ots-message-item.ots-message-sent
  .ots-item-text {
  background: #80ccf3;
  color: #fff;
  border-radius: 5px;
  position: relative;
  padding: 10px;
  min-height: auto;
}
.text-chat-container
  .ots-text-chat
  .ots-messages-holder
  .ots-message-item.ots-message-sent
  .ots-item-text:before {
  border-color: transparent;
  left: 100%;
  border-left-color: #80ccf3;
  border-width: 5px;
  top: 10px;
}
.text-chat-main .ots-item-text img {
  width: 100px;
}
.video-chat-main .ots-item-text img {
  width: 60px;
}
.text-chat-main .uploadattachBtn {
  top: -2px;
  position: absolute;
  left: -1px;
  background-color: var(--common-button-color);
  color: #fff;
  padding: 3px 8px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 600;
}
.chatQstnBtn {
  font-size: 10px;
  min-width: auto;
  padding: 3px 15px;
  font-weight: 600;
  color: var(--common-button-color);
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid var(--common-button-color);
  margin: 10px 10px;
  background-color: #fff;
  text-decoration: none;
}
.ots-text-chat .ots-messages-holder .ots-message-item .ots-user-name-initial {
  width: 30px;
  height: 30px;
  line-height: 32px;
  font-size: 16px;
}
body .ots-text-chat .ots-messages-holder .ots-messages-alert {
  padding: 15px;
  min-height: auto;
}

/* video chat page */
.video-chat-wrapper {
  background-color: #e6ecf0;
}
.video-chat-main {
  height: 93vh;
  margin: 0px auto;
  background-color: #e6ecf0;
  padding: 3vh 0 8vh;
}
.video-chat-container {
  background-color: #fff;
  border-radius: 20px;
  margin-left: 2%;
  overflow: hidden;
}
.video-chat-container .ots-text-chat {
  border-radius: 20px;
  top: 60px;
}
.video-chat-container .ots-text-chat .ots-send-message-box {
  width: 100%;
  background-color: #fff;
}
.video-call-btn {
  padding-left: 3rem;
  position: absolute;
  z-index: 2;
}
.video-chat-main.video-chat-fullScreen {
  height: 100vh;
  padding: 0;
  width: 100%;
}
/* .video-chatwrapper-fullScreen .video-call-btn {
  display: none;
} */
.video-chat-main.video-chat-fullScreen .App-video-container {
  width: 100%;
  border-radius: 0;
}
/* .video-chat-main.video-chat-fullScreen .video-chat-container {
  display: none;
} */
.video-chat-main .video-screen-icon {
  background-image: url("../image/icons/fullScreen.png");
  width: 25px;
  height: 25px;
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 999;
  background-size: cover;
  opacity: 0.3;
}
.video-chat-main .video-screen-icon:hover {
  opacity: 1;
  cursor: pointer;
}
.video-chat-main.video-chat-fullScreen .video-screen-icon {
  background-image: url("../image/icons/smallScreen.png");
}
.video-chat-main.video-chat-fullScreen .App-control-container, 
.App-control-container-dup
{
  width: 100%;
}
/* .video-chat-container .ots-text-chat .ots-send-message-box {
  bottom: -60px;
} */
.ots-text-chat .ots-messages-holder .ots-message-item .ots-item-timestamp {
  color: #9a9a9a;
  font-size: 10px;
}
.voice-recordBtn {
  position: absolute;
  width: 20px;
  right: 10px;
  bottom: 11px;
  z-index: 1;
  cursor: pointer;
}
.voice-recordBtn .voice-recordImg
{
  width:20px;
}
.App-header {
  height: 30px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 6px;
  position: absolute;
  bottom: 8px;
  background-color: #fff;
  z-index: 1;
  width: 92.5%;
  overflow: hidden;
  border-radius: 20px;
  margin-left: 2%;
}
.App-header audio
{
  height: 30px;
}
.voice-sndIcon
{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  right: 0;
  top: 7px;
  background-color: #259de8;
  outline: 0;
  border: 0;
  border-left: 1px solid #c6c6c6;
  background-image: url("../image/media/sent-white.png");
  background-repeat: no-repeat;
  font-size: 0;
  background-position: center;
}
.record-stop, .recording-icon, .recording-delete
{
  background-color: unset;
  border: 0;
}
.recording-icon-img
{
  width: 120px;
  height: 25px;
}
.record-stop-img, .recdeleteImg-icon-img
{
  width: 15px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.App-chat-details {
  width: 100%;
}

@media (max-width: 1024px) {
  /* .ots-text-chat .ots-messages-holder {
    height: calc(57vw * 0.6);
  } */
}

@media (max-width: 992px) {
  /* .App-control-container, .App-control-container-dup {
    justify-content: left;
    padding-left: 15px;
  } */
  
  body .App-control-container, body .App-control-container-dup
  {
    bottom: 1vh;
  }
}
@media (max-width: 767px) {
  .App-header {
    width: 86.5%;
  }
  .App-video-container,
  .App-chat-container,
  .App-control-container {
    width: 100%;
  }
  /* .App-control-container, .App-control-container-dup 
  {
    top: 20px;
    bottom: auto;
  } */
  .video-chat-container .ots-text-chat {
    top: 65px;
}
  .App-video-container {
    height: 300px;
  }
  .video-chat-fullScreen .App-video-container {
    height: 100vh;
  }
  .text-chat-main .ots-text-chat .ots-messages-holder {
    height: 335px;
  }
  .App-main {
    display: block;
    height: auto;
    margin: 0px auto;
  }
  .text-chat-main {
    height: 395px;
  }
  .video-chat-container {
    height: 350px;
    margin: 0;
    margin-top: 20px;
    position: relative;
    right: auto;
    bottom: auto;
  }
  .video-chat-main .App-chat-container {
    height: 350px;
    margin: 0;
    margin-top: 20px;
    position: absolute;
    width: 60%;
    right: 15px;
    bottom: 14vh;
  }
  .App-video-container .video-container.small {
    /* top: auto;
    bottom: 20px; */
    width:80px;
    height: 80px;
  }
  .ots-text-chat {
    margin-bottom: 5px;
  }
  .App-chat-attach {
    bottom: 9px;
  }
  .ots-text-chat .ots-send-message-box input[type="text"],
  .ots-text-chat .ots-send-message-box textarea {
    font-size: 14px;
  }
  .messageFileUpload {
    height: 17px;
  }
  .ots-text-chat .ots-send-message-box button {
    width: 27px;
    height: 27px;
    top: 3px;
  }
  .ots-icon-check {
    background-size: 18px;
  }
  .ots-text-chat .ots-send-message-box input[type="text"] {
    padding: 6px;
    padding-left: 15px;
  }
  .video-call-btn {
    padding-left: 15px;
  }
  .chat-dr-question.input-design {
    padding: 0.15rem 1rem;
  }
  .chat-bgbtn {
    padding: 5px 10px;
  }
  .video-chat-container .ots-text-chat .ots-messages-holder {
    height: 240px;
  }
  .text-chat-container .ots-text-chat .ots-send-message-box {
    width: 89%;
  }
}


@media (max-width: 570px) 
{
  .ots-text-chat .ots-messages-holder .ots-message-item .ots-item-text {
    margin-left: 45px;
  }
  .ots-text-chat
    .ots-messages-holder
    .ots-message-item.ots-message-sent
    .ots-item-text {
    margin-right: 45px;
  }
  .ots-text-chat .ots-messages-holder .ots-item-text audio {
    max-width: 245px;
  }
}

.ots-hiddenn{
  display: none !important;
}