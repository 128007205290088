 .drRegistrationForm .fileUpload-medical,
 .drRegistrationForm .fileUpload-medical:hover {
     padding: 4px 0px;
     border: none;
 }
 
 .input-file-container {
     position: relative;
 }
 
 button:focus,
 input[type="button"]:focus,
 input[type="submit"]:focus {
     outline: 0;
     border-color: var(--focus-input-color);
 }
 
 .js .input-file-trigger {
     display: block;
     padding: 5px 20px;
     border-radius: 20px;
     font-size: 1em;
     transition: all .4s;
     cursor: pointer;
     color: var(--theme-color);
     margin: 0;
     border: 1px solid var(--theme-color);
 }
 
 .js .input-file {
     position: absolute;
     top: 0;
     left: 0;
     /* width: 225px; */
     opacity: 0;
     /* padding: 14px 0; */
     cursor: pointer;
     color: var(--theme-color);
 }
 
 .js .input-file:hover+.input-file-trigger,
 .js .input-file:focus+.input-file-trigger,
 .js .input-file-trigger:hover,
 .js .input-file-trigger:focus {
     color: var(--focus-input-color);
     border-color: var(--focus-input-color);
 }
 
 @media (max-width: 1199px) and (min-width: 993px) {
     .drListMain {
         min-width: 100%;
     }
     .prescrptn-left-main {
         width: 66%;
         font-size: 12px;
     }
     .prescrptn-left-main .label-text,
     .prescrptn-left-main .input-design,
     .prescrptn-left-main .react-datepicker__input-container input {
         font-size: 10px;
     }
     .input-design-freq {
         width: 35px;
     }
     .prescrptn-right-main {
         width: 320px;
         font-size: 12px;
     }
     .prescrptn-right-main .formButton-sm {
         font-size: 8px;
         padding: 3px 9px;
     }
     .prescrptn-right-main .react-tabs__tab {
         font-size: 12px;
     }
     .blogpost-main {
         padding: 0 25px;
     }
     .inputdesignSpace {
         width: 53%;
     }
     .selecttitlewidth {
         width: 44%;
     }
 }
 
 @media (min-width: 992px) {
     .doctorDetailProfile .drServiceMain .drServiceSp img {
         margin-right: 5px;
     }
     .drService-rate {
         font-family: "Times New Roman", Times, serif;
     }
     .custom-search-sub {
         max-width: 16%;
         padding-right: 0;
     }
     .custom-search-subtn {
         max-width: 120px;
         padding-right: 0;
     }
     .appnt-date-time {
         width: 100px;
         max-width: 100px;
     }
     .appnt-center {
         max-width: 31%;
         flex: 0 0 31%;
     }
     .appnt-type {
         max-width: 13.666667%;
         flex: 0 0 13.666667%;
     }
     .appnt-status {
         flex: 0 0 17.666667%;
         max-width: 17.666667%;
     }
 }
 
 @media (max-width:1199px) {
     /* .health-track-card-right .tbody {
         width: 450px;
     } */
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 113px;
     }
     .health-track-card-right.hlth-trck-pdf .tbody {
         width: 557px;
     }
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th:nth-child(3),
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td:nth-child(3) {
         /* width: 215px; */
         width: 210px;
     }
     .sh-blog-infos .sh-d-center {
         display: block;
     }
     .sh-dwnld .formButton {
         padding: 5px 10px;
         min-width: auto;
     }
     .ph-hlthtracker-pdf.health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .ph-hlthtracker-pdf.health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 145px;
     }
     .ph-hlthtracker-pdf.health-track-card-right.hlth-trck-pdf .tbody {
         width: 730px;
     }
     .ph_tblWrap tr th.ph_res_tbl_disease {
         width: 290px!important;
     }
     .ph_htPdf table.ph_tblmilestne td span {
         padding: 5px 40px;
     }
     /* Css updated begins here */
     .purpleOuter .drRegistrationForm {
         padding: 20px 2%;
     }
     .purpleOuter .formsectnWidth {
         width: 100%;
     }
     /* Css updated ends here */
     .analytics_dashhead{
        font-size: 12px;
        white-space: nowrap;
     }
     .analytics_fromto input {
        width: 80px;
        font-size: 12px;
    }
    .analytics_datediv svg {
        margin-right: 5px;
    }
 }
 
 @media (max-width: 1024px) {
     .priceMain {
         padding: 50px 100px;
     }
     .appntmnt-list-tabmain-web {
         display: none;
     }
     .appntmnt-list-tabmain-mob {
         display: block;
     }
     .form-section {
         font-size: 14px;
     }
     .appnt-btn {
         top: 35px;
     }
     .drDetailHospitalDet {
         padding-left: 20px;
     }
     .category-sub {
         width: 100%;
     }
     .LinksList img {
         width: 20px;
     }
     .QckLinksNew .LinksList li span.videoSpan {
         padding-left: 7px;
     }
     .health-track-card-right.hlth-trck-pdf .tbody {
         width: 490px;
     }
     .health-track-card-right.hlth-immunetb table th,
     .health-track-card-right.hlth-immunetb table td {
         width: 104px;
     }
     .health-records-steps {
         padding-right: 0;
     }
     .sh-blocks .fa-trash.ml-5 {
         margin-left: 2rem !important;
     }
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 100px!important;
     }
     .ph-hlthtracker-pdf.health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .ph-hlthtracker-pdf.health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 120px!important;
     }
     .labelclinic-colon::after {
         top: 1px !important;
     }
     .input-design-widthclinic {
         width: 80% !important;
     }
     .hospitallistUlclinic-width {
         width: 80%;
     }
     .attchment-adddiv {
         width: 17%;
     }
 }
 
 @media (max-width: 992px) {
     .LinksList img {
         width: 24px;
     }
     .QckLinksNew .LinksList li span.videoSpan {
         padding-left: 10px;
     }
     .priceMain {
         padding: 50px 0;
     }
     .doctorListProfile,
     .doctorDetailProfile {
         width: 100%;
         margin-bottom: 20px;
         margin: 1% 0;
     }
     /* .doctorDetailProfile .drServiceMain {
        width: 90%;
        position: absolute;
        left: 5%;
        right: 5%;
        background-color: #fff;
        top: 15px;
        bottom: 15px;
    } */
     .doctorDetailMain .doctorDetailStatement {
         width: 100%;
         display: block;
         text-align: center;
         padding-left: 0;
     }
     .drSearch .drSearchBox {
         padding: 8px 25px 8px 10px;
     }
     .drSearch img {
         top: 11px;
         right: 7px;
     }
     .calendarDiv {
         width: 400px;
         left: auto;
         margin: 0px auto;
     }
     .menu {
         position: relative;
     }
     .menu .menu-ul {
         position: absolute;
         background-color: var(--theme-subcolor);
         right: 0;
         width: 100%;
         top: 73px;
         padding: 0;
         z-index: 1;
         display: none;
         padding-bottom: 20px;
         text-align: center;
     }
     .header {
         padding: 0;
         margin: 0;
         max-width: 100%;
     }
     .menu li {
         display: block;
         padding: 10px 2px;
     }
     .menu li a {
         color: #fff;
     }
     .header span.commonButton {
         white-space: nowrap;
         background-color: #ffffff;
         color: var(--theme-subcolor);
     }
     .subMenuMain {
         position: relative;
         width: auto;
         background-color: unset;
         box-shadow: unset;
         padding: 0px;
         top: 0;
         text-align: left;
     }
     .arrow-up:after,
     .arrow-up:before {
         position: unset;
         border: 0;
     }
     .downparaaboutus,
     .abtusparabottom2 p {
         width: 100%;
     }
     .rightaboutuspara {
         margin-top: 0;
     }
     .telehealthleftcontent {
         margin-top: 0;
     }
     span.menuIcon {
         display: block;
         margin-top: 14px;
         margin-right: 20px;
     }
     .menu li:last-child {
         float: none;
     }
     .menu li .subMenuList a {
         font-size: 14px;
     }
     .menu li a:hover {
         color: #fff;
     }
     .menu li a.menu-role-link:hover {
         color: inherit;
     }
     .home-about-bg {
         padding-top: 0;
     }
     .home-about-bg:after {
         position: unset;
     }
     .home-padding {
         padding: 20px 0 0;
     }
     .home p,
     .home .telehealthfeaturehead,
     .home .contactusbtn {
         text-align: center !important;
     }
     header {
         padding-top: 0;
         background-color: #efefef;
         box-shadow: 2px 2px 10px #cacaca;
         z-index: 1;
         position: relative;
     }
     .doctorDetailProfile p {
         font-size: 14px;
     }
     .doctorDetailProfile .drName {
         font-size: 1rem;
     }
     .columndvd {
         columns: 1;
     }
     .footer .footercontent1 p {
         text-align: left;
     }
     .fullcontactus {
         width: 100%;
         margin: auto;
     }
     .contactleftcontent {
         width: 100%;
     }
     .telehealthfeaturehead {
         font-size: 36px;
         font-weight: 400;
     }
     .contactrightcontent {
         width: 100%;
     }
     .fulltelehealth {
         width: 100%;
         margin: 0 auto;
     }
     .featureleftimg {
         margin-top: 15px;
     }
     .telehealthmiddlecontent {
         padding: 33px 5% 65px;
         margin-bottom: 15px;
     }
     .trustedbottomimg {
         padding: 8px;
         width: 50%;
     }
     .arrw-img-web,
     .menu-role-link.imgshowhide:hover .arrw-img-web {
         display: none;
     }
     .arrw-img-mob {
         display: inline-block !important;
     }
     .menu-role-link.imgshowhide:hover .imgshow.arrw-img-mob {
         display: none !important;
     }
     .right-text-about {
         max-width: 100%;
     }
     .container-auto {
         max-width: 100%;
     }
     .serviceWrap ul li {
         width: 31%;
         min-height: 350px;
     }
     .priceCover .popField {
         width: 130px;
         display: block;
         margin: auto;
     }
     .practiceTimelineDivision {
         width: 90%;
     }
     .video-left-wrapper {
         height: auto;
         margin-top: 10px;
         margin-bottom: 10px;
     }
     .video-left-wrapper .chatbottom-box {
         bottom: auto;
         left: auto;
         right: auto;
         position: relative;
     }
     .main-wrapper-smwidth,
     .main-wrapper-smwidth1 {
         width: 100%;
     }
     .form-section-smwidth {
         width: 500px;
     }
     .client-wrapper {
         width: 100%;
     }
     /* .appnt-brdr {
        flex: 0 0 400px;
        max-width: 400px;
        margin: auto;
    } */
     .appnt-brdr:after {
         display: none;
     }
     .appnt-date-time,
     .appnt-type {
         border-right: 1px solid #dedede;
     }
     .appnt-center {
         border-top: 1px solid #dedede;
         border-bottom: 1px solid #dedede;
         margin: 5px 0;
         padding: 10px 51px 0;
     }
     .appnt-type {
         padding-top: 0;
     }
     .appnt-status {
         padding-top: 0;
     }
     .appnt-btn {
         top: 25px;
     }
     .practiceTimeline-panel {
         padding: 10px 20% 20px;
     }
     .history-main .practiceTimeline-panel {
         padding: 30px;
     }
     .history-center,
     .history-date-time {
         border-bottom: 1px solid #dedede;
     }
     .history-reason {
         position: relative;
     }
     .history-reason:after {
         position: absolute;
         content: "";
         right: 0;
         width: 1px;
         height: 70px;
         top: 7px;
         background-color: #dedede;
     }
     .time-date-popup .popup-content {
         width: 60%;
     }
     .drDetailHospitalDet {
         padding: 0;
         position: static;
         top: auto;
         transform: none;
         margin: 15px 0;
     }
     .drDetailHospital .drDetailHospitalImg img {
         width: 200px;
     }
     .drDetailHospitalPadding {
         text-align: center;
     }
     .drDetHosptl {
         top: auto;
         transform: none;
         position: static;
     }
     .sectionFieldListSub {
         width: 100%;
     }
     li.menu-role .menu-role-link {
         background-color: transparent;
         color: #fff;
     }
     .video-appntmnt-list .react-tabs__tab {
         padding: 6px 2px;
     }
     .LinksList .dashBoardMenu li {
         width: 92%;
     }
     .mentalHeader span.commonButton {
         padding: 4px 15px;
         line-height: 35px;
         background-color: #fff;
         color: #000;
         margin-left: 0;
     }
     .healthDocSubDet {
         width: 84%;
     }
     .healthDocSubName {
         width: 68%;
     }
     .prescrptn-left-main {
         width: 55%;
         font-size: 12px;
     }
     .prescrptn-left-main .label-text,
     .prescrptn-left-main .input-design,
     .prescrptn-left-main .react-datepicker__input-container input {
         font-size: 10px;
     }
     .input-design-freq {
         width: 35px;
     }
     .prescrptn-right-main {
         width: 320px;
         font-size: 12px;
     }
     .prescrptn-right-main .formButton-sm {
         font-size: 8px;
         padding: 3px 9px;
     }
     .prescrptn-right-main .react-tabs__tab {
         font-size: 12px;
     }
     body .presc-add-main .popup-content {
         width: 97%;
     }
     .drService-subhead {
         font-size: 14px;
     }
     .promomessage {
         display: block;
     }
     .text-truncate-no-mobile {
         white-space: normal;
         text-overflow: unset;
         overflow: unset;
         word-break: break-word;
     }
     .main-wrapper,
     .main-wrapper-smwidth {
         margin-top: 10px;
     }
     .video-appntmnt-list .react-tabs__tab {
         font-size: 12px;
         font-weight: 600;
     }
     .dashbrd-menu-leftt {
         margin-bottom: 0px;
         min-height: 100%;
     }
     .drRegistrationForm {
         padding: 20px 0;
         padding-left: 4%;
         padding-right: 4%;
     }
     .subBx,
     .subBx:nth-child(even) {
         width: 80%;
         padding: 0.35rem;
     }
     .category-sub {
         font-size: 12px;
     }
     .bloguserList-text {
         font-size: 14px;
         line-height: 20px;
     }
     .blog-listbox {
         width: 45%;
         margin: 2.5%;
     }
     .category-sub {
         font-size: 12px;
     }
     .chat-span .btn-default {
         padding: 4px 15px;
     }
     .chat-outer .chat-span {
         margin-right: 5px;
     }
     .redactor-wrapper .rdw-image-modal {
         left: 0;
         transform: translateX(0);
         -webkit-transform: translateX(0);
         -moz-transform: translateX(0);
         -ms-transform: translateX(0);
         -o-transform: translateX(0);
     }
     .video-record-list {
         width: 31.3333%;
     }
     .breadcrumb li {
         font-size: 12px;
     }
     .breadcrumb i.fa {
         margin: 0 5px;
         font-size: 8px;
     }
     .inputdesignSpace {
         width: 58%;
         font-size: 12px;
     }
     .selecttitlewidth {
         width: 38%;
         font-size: 11px;
     }
     .reg-actn {
         right: 2%;
     }
     .textleftAlign {
         width: 100%;
     }
     .prfsnlStatemntWidth {
         width: 91%;
     }
     .formsectnWidthAdrs {
         width: 100%;
     }
     .drRegistrationForm .label-text {
         font-size: 12px;
     }
     .custom-search-subWidth {
         max-width: 24% !important;
     }
     .formButtonsearch-width {
         min-width: 147px !important;
     }
     .health-track-card {
         width: 100%;
         margin-bottom: 15px;
     }
     .health-track-card-right {
         padding-left: 15px;
     }
     .blogtag-main.sh-blog-infos.sh-newsfd .sh-d-center {
         display: flex;
         justify-content: left;
     }
     .blogtag-main.sh-blog-infos.sh-newsfd .sh-dwnld .formButton {
         padding: 8px 15px;
         min-width: auto;
     }
     /*  */
     table.ph-pdf-dwnlod thead {
         border: none;
         clip: rect(0 0 0 0);
         height: 1px;
         margin: -1px;
         overflow: hidden;
         padding: 0;
         position: absolute;
         width: 1px;
     }
     table.ph-pdf-dwnlod tr {
         border-bottom: 3px solid #ddd;
         display: block;
         margin-bottom: .625em;
     }
     table.ph-pdf-dwnlod td {
         border-bottom: 1px solid #ddd;
         display: block;
         font-size: .8em;
         text-align: right;
     }
     table.ph-pdf-dwnlod td::before {
         content: attr(data-label);
         float: left;
         font-weight: bold;
         text-transform: uppercase;
         width: 50%;
         text-align: left;
     }
     table.ph-pdf-dwnlod td:last-child {
         border-bottom: 1px solid #000;
     }
     .ph-pdf-dwnlod .table-bordered-hspSpecl th,
     .ph-pdf-dwnlod .table-bordered-hspSpecl td {
         width: 100%;
     }
     /*  */
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th:nth-child(4),
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td:nth-child(4),
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th:nth-child(5),
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td:nth-child(5) {
         width: 100%;
     }
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th:nth-child(3),
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td:nth-child(3) {
         width: 100%;
     }
     .ph-immune-div .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .ph-immune-div .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 100%!important;
     }
     .ph-milestne-div .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .ph-milestne-div .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 22.5%!important;
     }
     .labelclinic-colon {
         padding-right: 0 !important;
     }
     .hospitallistUlclinic-width {
         width: 100%;
     }
     .input-design-widthclinic {
         width: 85% !important;
     }
     .attchment-adddiv {
         width: 18%;
     }
     .blog-fleupload-top {
         margin-top: 0;
     }
     table.ph_tblWrap td::before {
         content: attr(data-label);
         float: left;
         width: 50%;
         text-align: left;
     }
     table.ph_tblWrap thead {
         border: none;
         clip: rect(0 0 0 0);
         height: 1px;
         margin: -1px;
         overflow: hidden;
         padding: 0;
         position: absolute;
         width: 1px;
     }
     table.ph_tblWrap tr {
         display: block;
         margin-bottom: .625em;
     }
     table.ph_tblWrap td {
         display: block;
         font-size: .8em;
         text-align: right;
     }
     table.ph_tblWrap td::before {
         /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
         content: attr(data-label)!important;
         float: left;
         font-weight: bold;
         text-transform: uppercase;
         color: #000;
     }
     table.ph_tblWrap.ph_tblmilestne td::before {
         width: 35%!important;
         position: unset!important;
         border: none!important;
         margin-top: 0px!important;
     }
     .ph_htPdf table td:nth-child(4),
     .ph_htPdf table td:nth-child(5) {
         text-align: right;
         color: #46ab34;
     }
     .ph_htPdf table tr:first-child td:last-child {
         border-radius: 0 0px 0 10px;
     }
     .ph_tblWrap tr td:first-child {
         border-radius: 0 0 0 0px;
         padding: 1px 10px!important;
     }
     .ph_htPdf table td {
         padding: 1px 10px!important;
     }
     .ph_htPdf table tr td:last-child,
     .ph_htPdf table tr:last-child td:last-child {
         border-radius: 0 0px 0 15px;
     }
     .ph_htPdf table td:nth-child(5) {
         border-radius: 0 0px 0 15px;
     }
     .ph_tblWrap tr td:first-child {
         border-radius: 0 15px 0px 0px;
     }
     .ph_tblWrap .ph_babyImg {
         display: none;
     }
     .ph_row {
         justify-content: center;
     }
     .ph_tblWrap {
         border-spacing: 0px 0px;
     }
     .ph_dob_info {
         margin-bottom: 3px!important;
         padding-left: 15px;
         font-size: 30px;
     }
     /* Baby milestne responsive bgns */
     .ph_tblWrap.ph_tblmilestne tr td:first-child {
         width: 100%;
         /* float: left; */
         text-align: left;
     }
     .ph_tblWrap.ph_tblmilestne tr td:last-child {
         width: 100%;
         /* float: left; */
         text-align: left;
     }
     .ph_tblWrap.ph_tblmilestne tr td:first-child::after {
         display: none;
     }
     .ph_tblmilestne {
         width: 100%;
     }
     .ph_htPdf table.ph_tblmilestne td span {
         padding: 0px 0px;
         text-align: left;
         display: block;
         background: transparent;
         box-shadow: none;
     }
     table.ph_tblWrap.ph_tblmilestne tr {
         display: block;
         margin-bottom: .625em;
         border-bottom: 2px solid #f2f5fa;
         box-shadow: 3px 3px 3px 0px #e3e4e5;
         background: #f2f5fa;
     }
     .ph_babyImageRight {
         display: none;
     }
     #ph_immunization {
         width: 100%;
     }
     .ph_tableOuter {
         height: 100%;
     }
     table.ph_tblWrap.ph_tblmilestne td::before {
         width: 15%!important;
     }
     .ph_htPdf table.ph_tblmilestne td:nth-child(2)::before {
         height: auto!important;
     }
     /* hospital promocode */
     .selectField-promoHsp {
         margin-right: 2px;
         width: 24%;
     }
     /* Css updated begins here */
     .purpleOuter .form-section .form-check-inline,
     .purpleOuter .form-section-smwidth .form-check-inline {
         margin-right: 20px;
     }
     /* Css updated ends here */
     
    .bloguserList-text-custom div * {
        line-height: 20px !important;
    }
    .container-page h1 {
        font-size: 24px;
    }
    .container-page h2 {
        font-size: 20px;
    }
    .container-page h3 {
        font-size: 18px;
    }
    .container-page h4, .container-page h5, .container-page h6 {
        font-size: 16px;
    }
 }
 
 @media (max-width:991px) {
     .health-track-card-right.hlth-immunetb table th,
     .health-track-card-right.hlth-immunetb table td {
         width: 100%!important;
     }
     .health-track-card-right.hlth-immunetb .tbody {
         width: 100%;
     }
     .sh-inner-cntnt {
         padding: 20px 0;
     }
     .health-track-card-right table th,
     .health-track-card-right table td {
         width: 100%;
     }
     .health-track-card-right.hlth-trck-pdf .tbody {
         width: 98%;
     }
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 100%!important;
     }
     .ht-terms img {
         width: auto;
     }
     .ht-dwnldbutton {
         justify-content: left;
     }
     .ph-hlthtracker-pdf.health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .ph-hlthtracker-pdf.health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 155px!important;
     }
     .ph-hlthtracker-pdf .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .ph-hlthtracker-pdf .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 115px!important;
     }
     table.ph-pdf-dwnlod td:nth-child(5) {
         border-bottom: 1px solid #000;
     }
     .health-track-card-right .d-center {
         justify-content: space-around;
     }
     .health-track-card-right.hlth-trck-pdf.hlth-baby .tbody {
         width: 100%;
     }
     /* Css updated begins here */
     .week-text {
         padding-left: 0;
     }
     /* Css updated ends here */
     .videocall_div {
         width: 80%;
     }
     .analytics_headleft{
        margin-top: 10px;
        margin-bottom: 10px;
     }
     .anaytics_datalabelsquare .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
        justify-content: flex-start;
    }
    .anaytics_datalabelsquare .apexcharts-canvas{
        margin: auto !important;
    }
    .analytics-sideMenu ul li{
        width: 100% !important;
    }
 }
 
 @media (max-width:800px) {
     .sh-blog-title.sh-dwnld .react-datepicker-wrapper {
         width: 30%;
         margin-right: 15px;
     }
     .content.sh-ida .breadcrumb {
         padding: 10px 25px;
         width: 97%;
         margin: 0 auto;
     }
     .health-track-card-right .d-center {
         justify-content: left;
     }
     .ht-terms.ph_disclaimer_info {
         width: 96%;
         margin: 0 auto;
         margin-top: 15px;
     }
     .health-track-dwd {
         padding-right: 15px;
     }
     .videocall_div {
         width: 98%;
     }
 }
 
 @media (max-width: 767px) {
    .analytics_rightselect,.analytics_fromto,.analytics_expertselect,.analytics_topemail{
        margin-bottom: 10px;
    }
    .analytics_fromto input {
        width: 100px;
    }
     .telehealthmiddlecontent p {
         font-size: 14px;
     }
     .telehealthmiddlecontent span.commonButton {
         font-size: 12px;
     }
     .prescrptn-add-section-right {
         border-left: 0;
     }
     .priceMain .priceSub {
         width: 94%;
         margin: 10px 4%;
     }
     .drSearch {
         width: 325px;
     }
     p.reviewerrightpara {
         padding: 20px 0px;
     }
     .col-md-3.footercontent1 {
         text-align: center;
     }
     label.footercontent {
         margin-left: 0;
         text-align: center;
     }
     p.reviewerrightpara,
     .footercontent1 {
         text-align: center;
     }
     .footer .footercontent1 p {
         text-align: center;
     }
     .doctorDetailProfile p,
     .drspecialist-hospital {
         font-size: 12px;
     }
     .priceMain .priceSub {
         width: 100%;
         margin: 10px 0;
     }
     /* .doctorDetailProfile.doctorListProfile {
        width: 98%;
    } */
     .LinksList .dashBoardMenu li {
         width: 94%;
     }
     .pagination>li>a,
     .pagination>li>span {
         padding: 5px 6px;
         height: 25px;
         width: 25px;
         font-size: 10px;
         font-weight: 400;
     }
     .drName {
         font-size: 15px;
     }
     .doctorDetailProfile .drspecialist {
         padding: 6px 15px;
         font-size: 12px;
     }
     .doctorDetailProfile .profileAvailable img {
         vertical-align: bottom;
         margin-right: 10px;
         width: 15px;
     }
     .doctorDetailProfile .profileAvailable {
         font-size: 10px;
     }
     .drSearch {
         width: 100%;
         margin-left: 0;
         margin-top: 10px;
     }
     .filter-search-wrapper {
         text-align: center;
     }
     .doctorDetailProfile .drServiceMain {
         width: 100%;
         height: auto;
         padding: 10px;
     }
     .doctorDetailLeft {
         width: 100%;
     }
     .doctors-list-tabmain {
         text-align: center;
         margin-top: 10px;
     }
     .doctors-list-tab {
         margin-left: 0;
         margin-right: 10px;
     }
     .specltyBox {
         margin-top: 20px;
         float: left;
         width: 100%;
         margin-bottom: 20px;
         text-align: center;
         padding: 15px 5px;
     }
     .specltyBox .specltyList {
         padding: 6px 12px;
         text-align: center;
         font-size: 10px;
         margin: 5px 2px 0;
     }
     .drDetailHospital {
         padding: 30px 20px;
     }
     p.aboutuspara,
     .downparaaboutus,
     p.reviewerrightpara,
     .abtusparabottom2 p,
     .contactleftcontent p,
     .home p,
     ul.ul-liike-p {
         font-size: 18px;
         line-height: 30px;
     }
     .home .row.p-5,
     .home .home-about-bg {
         padding: 0 !important;
     }
     .home .img-fluid {
         padding: 20px;
     }
     .home p,
     .home .telehealthfeaturehead,
     .home .contactusbtn,
     .home-about-bgImg {
         padding: 10px;
     }
     span.commonButton {
         padding: 9px 15px;
         font-size: 14px;
     }
     .telehealthfeaturehead {
         font-size: 26px;
     }
     .footer {
         padding: 70px 0 50px;
     }
     .row.bckgrndreviewer {
         padding: 23px 1px;
     }
     span.menuIcon img {
         width: 80%;
         margin-top: 3px;
     }
     .drSearch img {
         width: 15px;
     }
     .home-about-bgImg {
         padding: 30px 0 50px;
     }
     .aboutus {
         padding-bottom: 30px;
     }
     .calendarDiv {
         margin: unset;
         width: 100%;
     }
     .bgbannerImg {
         padding: 75px 5px;
     }
     .bgbannerImg-text {
         font-size: 28px;
     }
     .contactusbtn {
         margin-top: 1rem;
     }
     .rightaboutuspara {
         padding-right: 0px;
     }
     .why-purple-list {
         margin-bottom: 20px;
     }
     .telehealthfeaturehead-mb {
         margin-bottom: 10px;
     }
     .captchaBox {
         max-width: 92%;
         margin: auto;
     }
     .contactrightcontent .contactusbtn {
         margin-top: 4rem;
     }
     .form-section,
     .form-section-smwidth,
     .doctorDetail .doctoAddress,
     .form-section p,
     .form-section-smwidth p {
         font-size: 12px;
     }
     .doctorDetail .doctor-name {
         font-size: 1.2em;
     }
     /* .form-section .label-text:after {
        opacity: 0;
    } */
     .form-section .label-text-right,
     .form-section-smwidth .label-text-right {
         text-align: left;
     }
     .row-margin {
         margin-bottom: 15px;
     }
     textarea.textarea {
         font-size: 18px;
     }
     .practiceTimelineWrap .practiceTitleTop span {
         width: 100%;
         font-size: 12px;
     }
     .mrgRgtPrctmngt {
         width: 50%;
         vertical-align: top;
         padding-top: 5px;
         display: inline-block;
         margin-right: 0;
     }
     .messageCenterDiv .emailBox .sender,
     .messageCenterDiv .emailBox .subject {
         width: 100%;
     }
     .messageCenterDiv .emailBox h3,
     .messageCenterDiv .emailBox span {
         font-size: 0.8em;
     }
     .messageCenterDiv .messageBlock>li {
         display: inline-block;
     }
     .messageCenterDiv .sent_btn a,
     .messageCenterDiv .inbox_btn a {
         padding: 6px 12px;
         width: 100px;
         font-size: 0.9em;
     }
     /* .messageCenterDiv {
        background: #ededed;
    } */
     .messageCenterDiv {
         padding: 15px 0;
     }
     .fileAttachment {
         width: 100%;
     }
     .fileAttachment .attchedFile {
         width: 90%;
     }
     .messageCenterDiv .emailBox li {
         border: 1px solid #e4e4e4;
         margin-bottom: 15px;
     }
     .messageCenterDiv .search_message {
         width: 100%;
         margin: 10px 0px;
     }
     .messageCenterDiv .messageBlock {
         margin-bottom: 10px;
     }
     .main-wrapper1 {
         padding: 20px 0;
     }
     .info.infoDivSec,
     .attachment.attachmentDivSec {
         width: 100%;
         padding-left: 0;
     }
     .mailArea .date,
     .attachment a {
         font-size: 0.8em;
     }
     .mailArea thead {
         font-size: 12px;
     }
     .practiceTimelineDivision {
         width: 80%;
     }
     .blogVisitPage .blogVisitBlock .blogVisitHead a {
         font-size: 22px;
         line-height: 20px;
     }
     .blogVisitPage .blogVisitBlock .blogVisitPara {
         font-size: 16px;
     }
     .blogVisitPage .blogVisitBlock {
         width: 100%;
     }
     .memberHeadGrayBg {
         padding: 10px 15px;
     }
     .memberHeadGrayBg h3 {
         font-size: 18px;
     }
     .head-text,
     .form-head,
     body .terms-wrapper p {
         font-size: 14px;
     }
     .form-head-bg,
     .terms-subhead {
         font-size: 16px;
     }
     .fileRecordThumb-list {
         width: 47%;
         padding: 10px;
         margin: 1.5%;
     }
     .vitalSign-div .treatmentComplaintLeft {
         width: 60%;
     }
     .vitalSign-div .treatmentComplaintRight {
         width: 40%;
     }
     .caseSheet-div .treatmentComplaintLeft {
         width: 40%;
     }
     .caseSheet-div .treatmentComplaintRight {
         width: 60%;
     }
     .timelineListContent {
         padding: 10px;
     }
     .dentalProName span.elipsis {
         margin-top: 0;
         margin-left: 0;
     }
     body .popup-content,
     .signInWrap .popup-content,
     .time-date-popup .popup-content,
     body .popup-content,
     .signInWrap .newsfeed-popup,
     .time-date-popup .newsfeed-popup {
         width: 95%;
     }
     .modal-popup>.header,
     .time-date-popup .popup-content .header {
         font-size: 16px;
     }
     .modal-popup,
     .modal-popup>.content p {
         font-size: 14px;
     }
     .modal-popup>.close-popup {
         font-size: 18px;
         width: 20px;
         height: 20px;
         padding: 0px;
         line-height: 20px;
     }
     .signInWrap .modal-popup>.close-popup {
         right: -20px;
         top: -20px;
     }
     .time-date-popup .popup-content {
         padding: 20px;
     }
     .time-date-popup .react-datepicker-wrapper,
     .time-date-popup .app-popup-time {
         width: auto;
         margin: auto;
     }
     .parteners-main {
         text-align: center;
     }
     .parteners-list {
         width: 90%;
         margin: 10px auto;
     }
     .parteners-detail .parteners-detailtext {
         font-size: 14px;
         line-height: 20px;
     }
     .parteners-list .parteners-detail {
         height: auto;
     }
     /*
  .doctorDetailMain .doctorDetailStatement p {
    text-align: center;
  } */
     .footercontent2 {
         margin-top: 30px;
     }
     .main-wrapper,
     .main-wrapper-smwidth {
         padding: 30px 10px;
     }
     .radio-item input[type="radio"]:checked+label:after {
         top: 6px;
     }
     .form-section-smwidth {
         width: 95%;
     }
     .cancelButton,
     .formButton,
     .formButtonBg,
     .formButton-grey {
         font-size: 12px;
         min-width: auto;
         padding: 5px 20px;
     }
     .formButton-sm {
         font-size: 10px;
         padding: 3px 15px;
     }
     .doctorDetail {
         padding: 15px;
     }
     .doctorDetailProfile .det-drServiceMain {
         margin-top: 20px;
     }
     .practiceTimeline-panel {
         padding: 10px 20px;
         float: none;
         margin: 20px auto;
         width: 98%;
         padding-bottom: 20px;
     }
     .appnt-center,
     .appnt-date-time,
     .appnt-type {
         border: 0;
     }
     .appnt-brdr {
         padding: 10px 0px;
         border-bottom: 1px solid #dedede;
         margin: 0;
     }
     .appnt-type-text,
     .appnt-status-text {
         display: inline-block;
     }
     .appnt-type,
     .appnt-brdr,
     .appnt-status-text {
         font-size: 12px;
     }
     .appnt-btn {
         position: static;
         float: right;
         padding: 2px 10px;
         margin: 0;
     }
     .appnt-brdr br {
         display: none;
     }
     .appnt-drName,
     .appnt-hospitalName {
         display: block;
         text-align: center;
         width: 100%;
     }
     .modal-popup>.content {
         padding: 10px 0px;
     }
     .table-section {
         margin-top: 10px !important;
     }
     .border-underline:after {
         left: 32%;
         right: 32%;
     }
     .messages .message .text_wrapper {
         max-width: 73%;
     }
     .breadcrumb {
         padding: 10px 15px;
     }
     table {
         font-size: 12px;
     }
     .memberHead {
         padding: 10px 15px;
     }
     .memberHead img {
         width: 25px;
         margin-right: 10px;
     }
     .dashboard-right .accordion__item {
         padding: 0 10px;
     }
     .fileUpload,
     .fileUpload:hover {
         width: 160px;
         font-size: 12px;
     }
     .messageCenterDiv .emailBox .inbox-name,
     .messageCenterDiv .emailBox .inbox-sendto {
         font-size: 14px;
     }
     .form-subhead-brdr,
     .form-subhead {
         font-size: 14px;
     }
     .week-text {
         padding-left: 10px;
     }
     .hospBox {
         height: auto;
     }
     .serviceWrap ul li {
         width: 100%;
         margin: 10px 0;
         min-height: 100%;
     }
     .priceCover {
         display: flex;
         width: 250px;
         margin: 10px auto;
     }
     .tabIcon span {
         margin: 0;
     }
     .formButton-grey {
         padding: 5px 18px;
     }
     .history-brdr:after,
     .history-reason:after {
         width: 0;
     }
     .history-center,
     .history-reason,
     .history-type,
     .history-status,
     .history-date-time,
     .history-type-div {
         padding: 10px 20px;
     }
     .history-center,
     .history-reason,
     .history-type,
     .history-type-div {
         border-bottom: 1px solid #dedede;
         margin: 0;
     }
     .history-type-text,
     .history-status-text {
         display: inline-block;
     }
     .history-type,
     .history-reason,
     .history-status,
     .history-status-text {
         font-size: 14px;
     }
     .radio-item-active {
         padding-bottom: 3px;
     }
     .drDetHosptl {
         padding-top: 0;
     }
     .calendarLoading:after {
         left: 52%;
     }
     .notificationCallMain .popup-content {
         width: 90%;
         padding: 30px 10px;
     }
     .healthDocMain {
         width: 300px;
         border: 0;
         padding-left: 30px;
     }
     .healthDocSubDet {
         width: 90%;
     }
     .healthDocSubName {
         width: 77%;
     }
     .healthDocListMain {
         height: auto;
     }
     .doctorDetailLeft .drspecialist-text,
     .doctorDetailLeft .drspecialist-text,
     .doctorDetailLeft .drspecialist,
     .doctorDetailLeft .drName {
         overflow: unset;
         -webkit-line-clamp: unset;
         white-space: normal;
     }
     .terms-head {
         font-size: 20px;
     }
     .terms-wrapper ol,
     .terms-wrapper ul {
         padding-left: 15px;
     }
     body .terms-wrapper-popup ul,
     body .terms-wrapper-popup ul {
         padding-left: 20px;
     }
     .notificationCallMain {
         width: 100%;
         right: 0;
         top: 0;
         bottom: 0;
         height: 100%;
         background-color: #d4edda;
     }
     .notificationCallMain .alert-success {
         background-color: #d4edda;
         position: absolute;
         top: 50%;
         transform: translate(-50%, -50%);
         left: 50%;
         border: 0;
     }
     .notificationCallMain .blink-notfctn {
         width: 65px;
         margin-top: 135px;
         margin: 0px 6%;
     }
     .prescription-main {
         font-size: 10px;
     }
     .prescription-maindate {
         width: 75px;
     }
     .prescription-subhead,
     .prescription-subdet {
         font-size: 8px;
     }
     .popup-med-template-list {
         width: 97%;
     }
     .prescrptn-left-main {
         width: 100%;
     }
     .prescrptn-right-main {
         float: left;
         width: 100%;
         padding: 0;
     }
     .prescrptn-right-main .formButton-sm {
         font-size: 10px;
         padding: 3px 8px;
     }
     body .popup-attach {
         width: 95%;
     }
     .healthDocListSlider .healthDocSubImg {
         height: 60vh;
     }
     .appntmnt-list-tab {
         font-size: 9px;
         display: inline-block;
         padding: 2px 7px;
     }
     .appntmnt-list-tabmain-mob {
         padding-bottom: 5px;
     }
     .formButton-apply {
         padding: 3px 25px 5px;
     }
     .input-design,
     .react-datepicker__input-container input {
         padding: 0.25rem 0.5rem;
     }
     .fileUpload-medical-sm {
         padding: 2px 10px;
     }
     .mediaTitle {
         font-size: 1rem;
         font-weight: 600;
     }
     .mainBx {
         padding: 10px;
     }
     .drRegistrationForm .label-text {
         text-align: left;
     }
     .drRegistrationForm .label-text-cln:after {
         content: "";
     }
     .subBx,
     .subBx:nth-child(even) {
         width: 100%;
         padding: 0.35rem;
     }
     .main-wrapperReg {
         padding: 10px;
     }
     .blog-detls {
         font-size: 8px;
         padding-right: 10px;
     }
     .bloguser-Subhead {
         font-size: 16px;
     }
     .blog-textDate {
         font-size: 12px;
     }
     .blog-breadcrumbtext {
         font-size: 11px;
     }
     .drSearch .drSearchBox {
         font-size: 13px;
     }
     .rdw-option-wrapper {
         min-width: 14px;
         height: 14px;
     }
     .rdw-editor-toolbar {
         font-size: 13px;
     }
     .blog-listbox {
         width: 94%;
         margin: 15px 3%;
     }
     .bloguser-text {
         font-size: 20px;
     }
     .blog-detls {
         font-size: 10px;
         /* padding-right: 4px; */
     }
     .Blogsubtext,
     .Blogsubtext p {
         font-size: 12px;
         line-height: 22px;
         padding: 5px 0;
         margin: 0;
     }
     .Blogsubtext span,
     .Blogsubtext h5,
     .Blogsubtext h5 span,
     .Blogsubtext h6,
     .Blogsubtext h6 span {
         font-size: 12px !important;
         line-height: 22px !important;
         margin-bottom: 0;
     }
     .Blogsubtext h1,
     .Blogsubtext h1 span {
         font-size: 20px !important;
     }
     .Blogsubtext h2,
     .Blogsubtext h2 span {
         font-size: 18px !important;
         line-height: 22px;
     }
     .Blogsubtext h3,
     .Blogsubtext h3 span {
         font-size: 16px !important;
         line-height: 22px;
         margin-bottom: 5px;
     }
     .Blogsubtext h4,
     .Blogsubtext h4 span {
         font-size: 14px !important;
         line-height: 22px;
         margin-bottom: 5px;
     }
     .Blogsubtext ul,
     .Blogsubtext ol {
         padding-left: 20px;
     }
     .BlogsubtextLi {
         font-size: 12px;
         line-height: 21px;
     }
     .BlogsubHead {
         font-size: 18px;
     }
     .term-condtn-main {
         font-size: 12px;
     }
     .drDetailServicemainTab .react-tabs__tab-list {
         width: 100%;
         display: flex;
         overflow: auto;
     }
     .drDetailServicemainTab .react-tabs__tab {
         width: auto;
         white-space: nowrap;
     }
     .addNew-btn {
         padding: 5px 10px;
         font-size: 13px;
         margin-top: 5px;
     }
     .add-new-plus {
         margin-right: 3px;
     }
     .blog-category-menu {
         display: block;
     }
     /* .category-list {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    left: -100%;
    padding-left: 10px;
    width: 100%;
    transition: 1s;
  }
  .category-list.category-list-active {
    left: 0;
  } */
     .readmoreBlog,
     .readmoreBlog:hover {
         padding: 3px 10px;
         font-size: 10px;
     }
     .category-main .category-list {
         display: flex;
         overflow-x: scroll;
     }
     .category-main .category-list::-webkit-scrollbar {
         display: none;
     }
     /* Hide scrollbar for IE, Edge and Firefox */
     .category-main .category-list {
         -ms-overflow-style: none;
         /* IE and Edge */
         scrollbar-width: none;
         /* Firefox */
     }
     .category-sub {
         width: auto;
         white-space: nowrap;
     }
     .category-list a {
         margin-right: 5px;
     }
     .blog-listImg {
         height: auto;
         width: 100%;
         min-height: auto;
         max-height: initial;
     }
     body .sectionTag {
         margin: 20px 10px;
     }
     .redactor-wrapper .rdw-image-modal {
         left: 50%;
         transform: translateX(-30%);
         -webkit-transform: translateX(-30%);
         -moz-transform: translateX(-30%);
         -ms-transform: translateX(-30%);
         -o-transform: translateX(-30%);
     }
     .textleftAlign {
         width: 75%;
     }
     .doctrlabelTop {
         top: 0;
     }
     .inputdesignSpace {
         width: 74%;
         font-size: 14px;
     }
     .selecttitlewidth {
         width: 24%;
         font-size: 14px;
     }
     .addhosptalButn {
         margin: 0 !important;
     }
     .labelWidthH {
         width: 100%;
     }
     .prfsnlStatemntWidth {
         width: 100%;
         height: 80px;
     }
     .txtHospitalNameWidth {
         width: 128%;
     }
     .AppintmntTablewidth {
         width: 100%;
         padding-right: 0;
     }
     .drRegistrationForm .form-section-bgcolor {
         width: 100%;
     }
     .bg-border {
         width: 100%;
     }
     /* .drRegistrationForm .form-section {
         width: 116%;
     } */
     .drRegistrationForm .form-section {
         width: 100%;
     }
     .formButtonsearch-width {
         min-width: 54%;
     }
     .custom-search-subWidth {
         max-width: 100% !important;
     }
     .health-track-card-right .tbody {
         width: 100%;
         display: contents;
     }
     .health-records-opt .fileUpload-medical,
     .health-records-opt .fileUpload-medical:hover {
         min-width: 140px;
         padding: 5px 10px;
     }
     .sh-blg .blog-listImg {
         height: auto;
         width: 100%;
         min-height: auto;
         max-height: initial;
     }
     .sh-blog-head .bloguserList-text-dtls {
         font-size: 20px;
         line-height: 26px;
     }
     .bloguserList-text {
         height: auto;
     }
     .sh-ida .react-datepicker__input-container input {
         margin-left: 15px;
         width: 95%;
     }
     .sh-textarea {
         width: 98%;
     }
     .sh-lbl {
         padding: 0 15px;
     }
     .sh-res-addimg {
         margin-left: 15px;
     }
     .sh-idabranch {
         margin-top: 15px;
     }
     .sh-blocks .w-75 {
         width: 80% !important;
     }
     .input-design-widthclinic {
         width: 84% !important;
     }
     .formsectnWidth-clinic {
         width: auto !important;
     }
     .attchment-adddiv {
         width: 40%;
     }
     .ph_babyicons {
         display: flex;
     }
     .ht-terms.ph_disclaimer_info {
         width: 96%;
         margin: 0 auto;
         margin-top: 15px;
     }
     /* hospital promocode */
     .selectField-promoHsp {
         margin-right: 4px;
         width: 48%;
     }
     .label-reg-displytxt {
         display: contents;
     }
     .label-reg-padd {
         padding-left: 0;
     }
     .purpleOuter .drRegistrationForm {
         padding: 20px 5%;
     }
     .analytics_headleft{
        flex-wrap: wrap;
     }
     .analytics_datediv{
        margin-left: 0;
        margin-bottom: 10px;
     }
     .analytics_dashhead{
        width: 100%;
        margin-bottom: 10px;
     }
     .analytics_datediv input{
        width: 100%;
     }
     .analytics_headright{
        flex-wrap: wrap;
     }
     .analytics_rightselect{
        margin-left: 0;
     }
 }
 
 @media (max-height: 670px) {
     .invite-network-banner {
         height: 35vh;
     }
     .pharmacyapp-wrapper .slick-dots {
         bottom: 5px;
     }
     .sp-banner-img {
         height: 40vh;
         object-fit: contain;
     }
     .purpleOuter .pr-5,
     .purpleOuter .px-5 {
         padding-right: 1rem !important;
     }
 }
 
 @media (max-width: 570px) {
    .chatbot-window{
        width: calc(100% - 30px);
        right: 15px;
    }
     .signInWrap .popup-content {
         padding: 30px;
     }
     .signUp h3 {
         font-size: 14px;
     }
     .drDetailServicemainTab .react-tabs__tab {
         width: 150px;
         padding-left: 0;
         margin-bottom: 5px;
     }
     .video-appntmnt-list .react-tabs__tab-list {
         text-align: center;
     }
     .video-appntmnt-list .react-tabs__tab {
         padding: 6px 3px;
         font-size: 11px;
         width: auto;
         white-space: nowrap;
         margin-right: 5px;
     }
     .notification-tag {
         width: 21px;
         height: 21px;
         font-size: 8px;
         line-height: 22px;
     }
     .price-section-list-Main {
         width: 100%;
     }
     .price-section-list-oldrate,
     .price-section-list-newrate {
         font-size: 36px;
     }
     .price-section-list {
         width: 100%;
     }
     .cross-price::after {
         left: 13%;
         width: 80%;
     }
     .chat-outer .chat-span {
         margin-right: 2px;
     }
     .btn-default {
         font-size: 12px;
         padding: 3px 5px;
         font-weight: 400;
     }
     .textwrappergroup {
         display: block;
         width: 100%;
         margin: 0;
     }
     .chat-bodygroup .messages .text-header .message-time {
         position: absolute;
         top: 10px;
         right: 10px;
         font-size: 9px;
         margin-top: 0;
     }
     .messages .message .avatar .avatarimg {
         width: 35px;
         height: 35px;
         border: 1px solid #cfcfcf;
     }
     body .textchat-namehead {
         font-size: 12px;
     }
     .chat-outer .form-head {
         width: 100%;
         display: block;
         margin-bottom: 5px;
     }
     .chatbottom-box {
         position: relative;
         left: 0;
     }
     .dashboard-right {
         padding: 10px;
     }
     h5 {
         font-size: 14px;
     }
     h4 {
         font-size: 18px;
     }
     body {
         font-size: 14px;
     }
     .form-section .selecttime,
     .form-section-smwidth .selecttime {
         padding: 0.25rem 0.5rem;
     }
     .signInWrap {
         width: auto;
         left: 5px;
     }
     .chat-outer .chat-header {
         padding: 5px 5px;
     }
     .hospBox {
         margin: 8px 5%;
     }
     .searchDocImg img {
         width: 100%;
         position: initial;
         background: #fff;
         border-radius: 50%;
     }
     .searchDocName,
     .searchDocDesignation {
         font-size: 11px;
     }
     .searchDocService img {
         position: initial;
         width: auto;
         height: 14px;
     }
     body .searchServiceIcon {
         margin: 0 0 0 8px;
     }
     .doctor-pay-box {
         width: 95%;
     }
     .promoCodeBox {
         width: 99%;
     }
     .invite-network-banner {
         height: 30vh;
     }
     .rdw-option-wrapper {
         min-width: 17px !important;
         margin: 0 3px !important;
     }
     .chat-span .btn-default {
         padding: 4px 10px;
         margin-bottom: 5px;
     }
     .redactor-wrapper {
         min-height: 600px;
     }
     .video-record-list {
         width: 48%;
     }
     .health-track-card {
         padding: 15px;
     }
     .health-trck-img {
         width: 35%;
     }
     .health-track-card-right table th:first-child,
     .health-track-card-right table td:first-child {
         width: 100%;
     }
     .health-track-card-right.hlth-trck-pdf h1 {
         font-size: 18px;
     }
     .health-track-card-right.hlth-trck-pdf h2 {
         font-size: 16px;
     }
     .health-track-card-right.hlth-trck-pdf {
         padding: 0 0px 0 10px;
     }
     .health-records-files h3 {
         margin-bottom: 10px;
     }
     .sh-blog-title.sh-dwnld p {
         font-size: 12px;
         margin-bottom: 5px;
     }
     .sh-blog-title.sh-dwnld .react-datepicker-wrapper {
         width: 60%;
         margin-bottom: 5px;
     }
     .sh-blog-savebtn {
         width: 15%;
     }
     .time-date-popup .newsfeed-popup {
         padding: 30px 15px;
     }
     .sh-blocks {
         padding-left: 30px;
     }
     .sh-rw {
         margin-right: 0!important;
         margin-left: 0!important;
     }
     .sh-blocks {
         width: 80%;
     }
     .sh-sve {
         margin-left: 20px;
     }
     .sh-ida .header {
         padding-left: 30px;
     }
     .ph-maintitle {
         margin: 5px 0 10px;
         font-size: 23px!important;
     }
     .sh-ida .breadcrumb {
         border: none;
     }
     .ph-hlthtracker-pdf .health-track-card-right.hlth-trck-pdf h1 {
         font-size: 15px;
     }
     .health-track-card-right.hlth-trck-pdf h2 {
         font-size: 14px;
     }
     .ph-hlthtracker-pdf .health-track-card-right.hlth-immunetb.hlth-trck-pdf table th,
     .ph-hlthtracker-pdf .health-track-card-right.hlth-immunetb.hlth-trck-pdf table td {
         width: 100px!important;
         font-size: 10px!important;
         padding: 2px 0px!important;
     }
     .ph-hlthtracker-pdf .ht-terms p {
         font-size: 10px!important;
     }
     .ht-terms {
         padding: 10px 8px;
     }
     .input-design-widthclinic {
         width: 75% !important;
     }
     .health-track-dwd {
         padding-right: 0;
     }
     .purpleOuter .input-design,
     .purpleOuter .react-datepicker__input-container input {
         margin-bottom: 3px;
     }
     .purpleOuter .txtHospitalNameWidth {
         width: 100%;
     }
     .purpleOuter .drRegistrationForm .purpleAppointments select.input-design,
     .purpleOuter .drRegistrationForm .purpleAppointments .input-design {
         width: 80px;
         font-size: 10px;
     }
     .purpleOuter .mb-2,
     .purpleOuter .my-2 {
         padding: 0px 0px 0 20px;
     }
     .purpleOuter .week-text {
         padding-left: 0px;
     }
     .accordionTab .btn-link {
        font-size: 14px;
    }
 }
 
 @media (max-width:425px) {
     .sh-dwnld .formButton,
     .sh-dwnld .sh-datepckr {
         width: 45%;
         text-align: left;
         padding: 5px 10px;
     }
     .sh-dwnld .sh-datepckr .input-design,
     .sh-dwnld .sh-datepckr .react-datepicker__input-container input {
         padding: 5px 10px;
     }
     .sh-dwnld .formButton.sh-rdmre {
         width: 100%;
     }
     .sh-blog-infos.sh-newsfd .sh-dwnld .formButton.sh-rdmre {
         width: auto;
     }
     .time-date-popup .newsfeed-popup {
         padding: 20px 2px;
     }
     .sh-ida .react-datepicker__input-container input {
         width: 93%;
     }
 }
 
 @media (max-width: 360px) {
     .sp-banner-content {
         width: 300px;
     }
     .pharmacyapp-wrapper .sp-banner-content-p {
         font-size: 12px;
     }
     .textleftAlign {
         width: 75%;
     }
     .inputdesignSpace {
         width: 74%;
     }
     .txtHospitalNameWidth {
         width: 135%;
     }
     .sh-dwnld .formButton,
     .sh-dwnld .sh-datepckr,
     .sh-dwnld .sh-datepckr .input-design,
     .sh-dwnld .sh-datepckr .react-datepicker__input-container input {
         width: 100%;
         padding: 5px 20px;
     }
     .sh-blocks {
         width: 85%;
     }
     .attchment-adddiv {
         width: 35%;
     }
 }
 
 @media (max-width:320px) {
     .sh-ida .divBtn {
         min-width: 115px;
     }
     .attchment-adddiv {
         width: 46%;
     }
 }