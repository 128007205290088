.sectionBorderadminMangmnt {
    border: 1px solid #ddd;
    border-radius: 7px;
}

.adminMangemnt-dashboard {
    padding: 30px 30px;
    margin: 0 auto;
}

.admin-dashBtns {
    width: 24%;
    display: inline-block;
    padding: 6px 10px;
    border: 2px solid #8300ff;
    border-radius: 10px;
    margin: 10px;
}

.admin-dashBtns a {
    color: #8300ff;
    text-decoration: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 800;
}

.admin-dashBtns:hover a {
    color: #fff;
}

.admin-dashBtns:hover {
    background-color: #8300ff;
    color: #FFF;
}

.admin-dc-dashBtns {
    width: 22%;
    display: inline-block;
    padding: 9px 10px;
    border: 2px solid #8300ff;
    border-radius: 10px;
    margin: 3px;
}

.admin-dc-dashBtns a {
    color: #8300ff;
    text-decoration: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 800;
}

.admin-dc-dashBtns:hover a {
    color: #fff;
}

.admin-dc-dashBtns:hover {
    background-color: #8300ff;
    color: #FFF;
}

.adminMangemntdc-dashboard {
    padding: 30px 0px;
    width: 950px;
    margin: 0 auto
}

.breadcrum-adminMangmnt {
    border-style: none;
    margin-top: 5%;
    border-color: #f5f5f5 #e5e5e5 #CCC;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    list-style: outside none none;
    padding-left: 0;
}

.breadcrum-adminMangmnt li:first-child a {
    padding-left: 2em;
    border-radius: 5px 0 0 5px;
}

.breadcrum-adminMangmnt a {
    padding: 6px 20px;
    float: left;
    text-decoration: none;
    color: black;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
    background-color: #ddd;
    background-image: linear-gradient(to right, #f5f5f5, #ddd);
}

.breadcrum-adminMangmnt a::before {
    border-left-color: #ccc;
    right: -1.1em;
    z-index: 1;
}

.breadcrum-adminMangmnt a::after,
.breadcrum-adminMangmnt a::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent;
    border-left: 1em solid;
    right: -1em;
}

.breadcrum-adminMangmnt a::after {
    z-index: 2;
    border-left-color: #ddd;
}

.breadcrum-adminMangmnt .current,
.breadcrum-adminMangmnt .current:hover {
    font-weight: bold;
    background: 0;
    color: #8300ff;
}

.breadcrum-adminMangmnt .current::after,
.breadcrum-adminMangmnt .current::before {
    content: normal;
}
.breadcrum-adminMangmnt .current{
    cursor: auto;
}

.breadcrum-adminMangmnt a::before {
    border-left-color: #ccc;
    right: -1.1em;
    z-index: 1;
}

.head-hospMangmntList {
    background-color: #FFF;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    line-height: 24px;
    clear: both;
    text-align: justify;
    padding-top: 4px;
    margin-bottom: 1%;

}

.addnewhspbtn {
    color: #333;
    text-decoration: none;
    line-height: 100%;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 0 2px 5px 0;
    background-color: #ececec;
    border: solid 1px #b8b8b9;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 30%), 0 1px 0 rgb(0 0 0 / 10%);
    width: auto;
    height: 30px;
    text-indent: -26px;
    padding: 8px 36px 6px 33px;
    background-image: url("../image/icons/addbtn.png");
    background-repeat: no-repeat;
    background-position: right center!important;
}

.tableHeader-HspMangemnt {
    width: 100%;
    height: 50px;
    background-color: #e0e0e0;
    padding: .6%;
    border: 1px solid #dcdcdc;
    clear: both;
    margin-bottom: 10px;
}

.selectField-HspMangmnt {
    float: left!important;
    margin-right: 1%!important;
}

.slecthsp {
    padding: 2px;
    width: 145px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    margin: 1px 0px 0px;
    border: 1px solid lightgray;
    height: 32px;
    color: #222;
}

.hspmngInput {
    padding: 1px;
    width: 145px;
    font-family: Helvetica, sans-serif;
    font-size: 14;
    margin: 1px 0px 0px;
    border: 1px solid lightgray;
    height: 32px;
    text-indent: 5px;
    color: #8f8f8f;
}

.searchbtn-hspMng {
    background-color: #8300ff;
    border-color: #8300ff;
    color: #FFF;
    border-radius: 1px;
    display: inline-block;
    font-size: 14px;
    padding: 5px;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    cursor: pointer;
    text-align: center;
    margin-right: 1%;
    width: 80px;
    font-weight: 800;
}

.table-bordered-hsp {
    border: 1px solid #ddd;
    border-collapse: separate;
    border-collapse: collapse;
    border-left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 0;
}

.table-bordered-hsp th,
.table-bordered-hsp td {
    border-left: 1px solid #ddd;
    padding: 8px;
    line-height: 20px;
    text-align: left;
    border-top: 1px solid #ddd;
    word-wrap: break-word;
}

.table-bordered-hsp th {
    font-weight: 700;
    background-color: #f2f2f2;
    vertical-align: middle!important;
}

.tablehead-hspmng {
    word-wrap: break-word!important;
}

.listLink-hspmangmnt {
    font-size: 12px;
    font-weight: 500;
    color: #09F!important;
    line-height: 30px;
    vertical-align: super;
    cursor: pointer;
    text-decoration: underline!important;
    white-space: nowrap;
}

.table-bordered-hsp tr:nth-child(even) {
    background-color: #fff !important;
}

.smssetngsWrap {
    width: 980px;
    margin: 30px auto;
    padding: 35px 20px;
    /* border: 1px solid #ddd; */
    overflow: hidden;
    clear: both;
}

.smssetngsTop {
    width: 500px;
    margin: auto;
    padding-bottom: 30px;
    clear: both;
    overflow: hidden;
}

.smssetngsTop label {
    padding-right: 15px;
    width: 130px;
    float: left;
    padding-top: 5px;
    font-size: 15px;
}

.smssetngsTop select {
    width: 250px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 36px;
    color: #8f8f8f;
    padding: 0 5px;
    float: left;
}

.smsSetngHead {
    clear: both;
    overflow: hidden;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    color: #337ab7;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
}

.smsSetngsContent {
    padding: 30px 0;
}

.smssetngscontentdiv {
    width: 50%;
    float: left;
    padding: 0 15px;
}

.smssetngscontentdiv .smsItem {
    overflow: hidden;
    clear: both;
    margin-bottom: 10px;
}

.smssetngscontentdiv .smsItem label {
    float: left;
    font-size: 14px;
    width: 240px;
    padding-top: 7px;
    text-align: right;
    padding-right: 15px;
    margin: 0;
}

.smssetngscontentdiv .smsItem input[type="text"],
.smssetngInput {
    width: auto;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 36px;
    color: #8f8f8f;
    padding: 0 5px;
    float: left;
    width: 180px;
    border-radius: unset;
}

.smssetngscontentdiv .smsItem input[type="text"]:focus,
.smssetngsTop select:focus {
    border: 1px solid #000;
}

.smssetngscontentdiv.smssetngCheckbox {
    width: 100%;
}

.smssetngscontentdiv .smsItem input[type="checkbox"] {
    width: 18px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 28px;
    color: #8f8f8f;
    padding: 0 5px;
    float: left;
}

.smssetngsWrap .smssetng-btn {
    padding: 15px 15px 0 0;
    float: right;
    clear: both;
}

.smssetng-svebtn {
    background-color: #8300ff;
    font-size: 14px;
    padding: 9px 25px;
    text-decoration: none;
    /* width: 80px; */
    display: inline-table;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    border: 0;
    font-weight: 900;
}

.smssetng-cancelbtn {
    background-color: #adadad;
    font-size: 14px;
    padding: 9px;
    text-decoration: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    width: 80px;
    font-weight: 900;
}
.smssetng-resetbtn {
    background-color: #c32e2e;
    font-size: 14px;
    padding: 9px;
    text-decoration: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    width: 80px;
    font-weight: 600;
  }
.smssetngscontentdiv .smsItem .react-datepicker-wrapper {
    display: block;
}

.smssetngscontentdiv .smsItem .react-datepicker__input-container {
    position: relative;
    display: block;
    width: auto;
}

.smssetngscontentdiv .smsItem .react-datepicker-popper {
    top: 3% !important;
    left: 19% !important;
}


/* .smssetngscontentdiv .smsItem .react-datepicker__triangle{
    display: none;
} */

.hspInformation-wraper {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    padding: 5px;
    width: 100%;
}

.hspInfoSubhead {
    font-size: 23px;
    font-weight: 600;
}

.hspinfo-contentdiv {
    height: auto;
    float: left;
    margin-top: 10px;
    width: 48%;
}

.hspInfofieldinputWidth {
    width: 100px !important;
}

.hspinfo-contentdiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 14px;
}

input.hspInfofieldinput {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    font-size: 13px;
    /* background: #ffffd8; */
}

.hspifoMantory {
    margin-left: 3px;
    display: inline-block;
    color: red;
}

input.hspInfofieldinput::placeholder {
    font-size: 13px;
}

select.hspInfofieldinput {
    padding: 1px;
    width: 260px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    /* background: #ffffd8; */
}

.hspworkdetails-wrapper {
    background-color: rgb(242 242 242);
    ;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    padding: 5px;
    width: 100%;
}

.hspwrkdetal-ofcehour {
    background-color: white;
    padding: 1%;
    height: 100%;
    min-height: 395px;
    width: 61%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    font-size: 14px;
}

.officehrsHead {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    clear: both;
    background-color: #f2f2f2;
    padding: 8px;
    font-size: 20px;
    font-weight: 600;
}

.offcehrDaydiv {
    padding: 1%;
    height: auto;
    display: inline-flex;
}

.offcehrDaydiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 164px;
    padding-top: 5px;
    font-size: 14px;
}

.offcehrDay {
    float: left!important;
    margin-right: 1%!important;
}

.offcehrDay select {
    padding: 1px;
    width: 135px;
    font-family: Helvetica, sans-serif;
    font-size: 1em;
    margin: 0 5px 3px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}

.offcehrDayFieldDiv {
    height: auto;
    float: left;
    width: 48%;
}

.offcehrDayFieldDiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 199px;
    padding-top: 5px;
    font-size: 14px;
}

.offcehrDayFieldDiv select {
    padding: 1px;
    width: 260px;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
}

.hspInfoSubhead span {
    color: red;
    font-size: 23px;
}

.hspspecializationDiv {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    /* height: 250px; */
    height: 150px;
    border: 1px solid gainsboro;
    margin: 10px 0 0 0;
    padding: 10px 0 0 10px;
}

.table-bordered-hspSpecl {
    /* border: 1px solid #ddd; */
    border-collapse: separate;
    border-collapse: collapse;
    border-left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 0
}

.table-bordered-hspSpecl th,
.table-bordered-hspSpecl td {
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: top;
    /* border-top: 1px solid #ddd; */
    word-wrap: break-word;
    width: 80px;
    /* border-left: 1px solid #ddd; */
}

.tdtxtLabel-hsp {
    display: inline-block;
    margin-left: 6px;
}

.immunisationTd {
    width: 20% !important;
}

.superadminDivBox {
    height: auto;
    /* width: 440px; */
    float: left;
    width: 48%;
}

.superadminDivBox label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 15px;
    clear: both;
    overflow: hidden;
    padding-top: 10px;
}

.superadminDivBox select {
    padding: 1px;
    width: 100px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}

.superadminDivBoxcover {
    clear: both;
    overflow: hidden;
    padding-top: 10px;
}

.hsptaltimeHead {
    background-color: #f2f2f2;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-table;
    padding: 5px;
    width: 100%;
}

.hsptimefieldDiv {
    float: left;
    margin-right: 1%
}

.hsptimefieldDiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    padding-top: 5px;
    font-size: 14px;
    color: #393939;
}

.hsptimefieldDiv select {
    padding: 1px;
    width: 100px;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}

.addimgeDivHsp {
    width: 32px;
    height: 32px;
    background-position: center;
    text-align: center;
    float: right;
    margin-bottom: 5px;
    margin-top: 5px;
}

.addimgeDivHsp img {
    cursor: pointer;
}

.serviceselectHsp {
    padding: 1px !important;
    width: 260px !important;
    font-size: 14px !important;
    border: 1px solid #d3d3d3 !important;
    height: 36px !important;
    margin: 0 0 5px !important;
    text-indent: 5px !important;
    color: #8f8f8f !important;
}

.hsptaltimeHead .hsptimefieldDiv {
    margin-top: 5px;
}

.textarea-hspservice {
    padding: 1px;
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    text-indent: 5px;
    color: #8f8f8f;
    min-height: 80px;
    max-height: 90px;
    width: 100%;
}

.label-hspservice {
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 15px;
}

.textarea-hspservicewidth {
    max-height: 230px;
}

.dashboardhspOuter {
    position: relative;
}

.backArowdImgdiv {
    position: absolute;
    top: -42px;
}

.backArowdImgdiv a {
    padding: 5px 0px;
    border-radius: 8px;
    color: #8400ff;
    font-size: 14px;
    font-weight: 600;
}
.backArowdImgdiv span{
    font-size: 16px;
    color: #8400ff;
}

.AddNewHspbtnDiv {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}

.pagination-HspList {
    text-align: right;
    width: 70%;
    display: inline-block;
}

.pagination-HspList ul li a {
    border-radius: 13%;
    height: 34px;
    width: 34px;
    font-size: 13px;
    font-weight: 900;
}

.pagination-HspList ul li a:hover {
    background-color: #8300ff;
}

.pagination-HspList ul li a:focus {
    background-color: #8300ff !important;
}

.pagination-HspList ul li a:active {
    background-color: #8300ff !important;
}

.pagination-HspList ul .active a {
    background-color: #8300ff !important;
}

.AddNewHspbtnDiv a,.AddNewHspbtnDiv span.addnewhspbtn {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.pagination-HspListwidth {
    width: 100%;
}

.hspinfoimgaeuploadDiv {
    width: 264px;
    float: left;
}

.hspinfo-btnupload {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: #8300ff;
    border-color: #8300ff;
    color: #FFF;
    border-radius: 1px;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    margin-right: 1%;
    width: 80px;
}

.hspinfo-btnupload input.hspinfoInputfieldupload {
    cursor: pointer;
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.seosectinTextHspMangemnt {
    color: red;
    font-size: 15px;
}

.generatebtn-seohsp {
    background-color: #8300ff;
    border: 0;
    padding: 8px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    width: 120px;
    font-size: 14px;
    font-weight: 900;
}

.generatebtnDiv-seohsp {
    margin-top: 20px;
}

.seofieldDivhsp label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 160px;
    padding-top: 5px;
    font-size: 14px;
}

.seofieldDivhsp-textarea {
    padding: 1px;
    width: 720px;
    max-width: 720px;
    min-width: 720px;
    /* font-family: Helvetica, sans-serif; */
    font-size: 14px;
    margin: 0 0 5px;
    border: 1px solid lightgray;
    text-indent: 5px;
    color: #8f8f8f;
    min-height: 80px;
    max-height: 90px;
    max-height: 90px;
}
.addHspPage-Wrapper{
    margin: 0 4px;
}

.addHspPage-Wrapper .react-tabs__tab--selected {
    border-color: var(--theme-color);
    color: #fff;
    border-radius: 5px 5px 0 0;
    background-color: var(--theme-color) !important;
}

.addHspPage-Wrapper .react-tabs__tab {
    padding: 5px 17px;
    background-color: #adadad38;
    border-radius: 5px 5px 0 0;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 600;
}

.hspManagement-GoogleMap iframe {
    width: 100%;
    height: 300px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.HspManagemt-btns {
    padding-top: 75px;
}

.linkmobhspsubDiv {
    clear: both;
    padding-top: 20px;
}

.linkhspGenerateBtn-div {
    text-align: center;
    ;
}

.linkhspGenerateBtn {
    border: 0;
    padding: 8px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    width: 256px;
    background-color: #8300ff;
    margin-top: 30px;
    font-size: 15px;
    font-weight: 800;
}

.linkhspgenerteInput {
    width: 100%;
    padding: 1px;
    font-size: 1.2em;
    border: 1px solid #d3d3d3;
    height: 32px;
    margin: 0 0 5px;
    text-indent: 5px;
    color: #8f8f8f;
    margin-top: 20px;
}

.linkgeneratedivOuter {
    background-color: #f4f4f4;
    padding: 30px;
    margin-top: 20px;
}


/* Add Images css begins here */

.ph-add-images .btn_upload {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    color: #fff;    
    background-color: #8300ff;
    border: 1px solid #8300ff;
    padding: 5px 12px;
    height: 30px;
    margin-bottom: 10px;
    vertical-align: top;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}
.imgUploadInline-hspaddimg{
    display: inline-block;
    margin: 0px 15px;
}
.imgUploadInline-hspaddimg img{
    width: 60px;
    height: 45px;
    object-fit: cover;
    object-position: center;
}

.ph-add-images .btn_upload:hover,
.ph-add-images .btn_upload:focus {
    background-color: var(--common-button-bgcolor);
}

.ph-add-images .yes {
    /* display: flex; */
    /* align-items: flex-start; */
    margin-top: 10px !important;
    /* padding-left: 18px; */
    display: inline-block;
    width: 300px;
    vertical-align: top;
    position: relative;
}
.imgCloseImg-hspadd{
    position: absolute;
    top: -6px;
    width: 22px;
}

.ph-add-images .btn_upload input {
    cursor: pointer;
    height: 100%;
    position: absolute;
    filter: alpha(opacity=1);
    -moz-opacity: 0;
    opacity: 0;
}

.ph-add-images .preview1 {
    visibility: hidden!important;
}

.ph-add-images .it {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
    margin-left: 10px;
    visibility: visible!important;
    border: 1px solid #00000030;
    padding: 5px;
}

.ph-add-images .btn-rmv1,
.ph-add-images .btn-rmv2,
.ph-add-images .btn-rmv3,
.ph-add-images .btn-rmv4,
.ph-add-images .btn-rmv5 {
    display: none;
}

.ph-add-images .rmv {
    cursor: pointer;
    color: #fff;
    border-radius: 14px;
    border: none;
    display: inline-block;
    background: rgb(255 1 1);
    margin: -10px -10px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    position: absolute;
}

.ph-add-images .rmv:hover {
    background: rgba(255, 0, 0, 0.7);
}

.ph-add-images .form-group {
    width: 95%;
}

.ph-addimagewraper {
    /* background: #e0e0e0; */
    /* padding: 25px; */
    margin-left: 5px;
    margin-top: 15px;
}
.ph-uploadedimg{
    position: relative;
    display: inline-block;
    width: 18%;
    margin: 4px;
}

.ph-uploadedimg img {
    width: 70%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}

.ph-imglist {
    padding: 30px 0px;
    padding-left: 18px; 
}

.ph-imglist .smssetng-svebtn {
    /* background-color: var(--theme-color); */
    /* margin: 30px -15px; */
    margin-right: 8px;
}
.ph-addimagewraper .smssetng-btn{
    text-align: right;
}
.addImagebtn-hspManagement{
    background-color: #8300ff;
    font-size: 14px;
    padding: 9px;
    text-decoration: none;
    width: 125px;
    display: inline-table;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin: 15px;
    border: 0;
    font-weight: 900;
}
.rmvbtn-imgeuplodHsp{
    cursor: pointer;
    color: #fff;
    border-radius: 14px;
    border: none;
    display: inline-block;
    background: rgb(255 1 1);
    margin: -10px -10px;
    width: 19px;
    height: 19px;
    font-size: 10px;
    position: absolute;
    right: 28px;
    top: 3px;
}
.hidden-menuImgUplod{
    background-color: #f3f3f3;
    padding: 30px;
    height: 249px;
    overflow-y: scroll;
    min-width: 400px;
    /* width: auto; */
    display: inline-block;
}
.addimgprevwImgdiv{
    display: inline-block;
    width: 32%;
    vertical-align: top;
}
.gendrfldHspdctr{
    font-size: 14px;
}
.doctorhspwrkdetal-ofcehour{
    background-color: white;
    padding: 1%;
    height: 100%;
    min-height: 395px;
    width: 70%;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    font-size: 14px;
}
.doctroffcehrDay select{
    padding: 1px;
    width: 100px;
    font-family: Helvetica, sans-serif;
    font-size: 1em;
    margin: 0 5px 3px;
    border: 1px solid lightgray;
    height: 36px;
    color: #8f8f8f;
}
.sheduleLabeldoctoffce{
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 164px;
    padding-top: 5px;
    font-size: 14px;
}
.sheduledivdoctr{
    display: inline-block;
    width: 30%;
    text-align: center;
}
.hspinfodoctor-contentdiv{
    height: auto;
    float: left;
    margin-top: 10px;
    width: 100%;
}
.hspinfodoctor-contentdiv label {
    float: left;
    text-align: right;
    margin-right: 15px;
    width: 195px;
    padding-top: 5px;
    font-size: 14px;
}
.educationdoctr-contentdiv label{
    float: left;
    text-align: left;
    margin-right: 5px;
    width: 122px;
    padding-top: 5px;
    font-size: 15px;
}
.educationdoctr-contentdiv{
    height: auto;
    float: left;
    margin-top: 10px;
    width: auto;
    margin-right: 20px;
}
.priceinputproduredetl{
    width: 70px !important;
}
.priceinrtext{
    color: red;
    font-size: 14px;
}
.doctrcheckboxDivhsp{
    width: 50%;
    display: inline-block;
    clear: both;
}
.offcialinfowrapperhsp-bg{
    background-color: #f2f2f2;
    padding: 4px 5px 40px;
}
.procedhsp-contentdiv label{
    float: left;
    text-align: right;
    margin-right: 15px;
    padding-top: 5px;
    font-size: 14px;
}
.procedhsp-contentdiv{
    height: auto;
    float: left;
    margin: 5px;
    width: 48%
}
.procedhsp-contentdivWidth{
    width: auto !important;
}
.addimgfield-div label{
    font-size: 15px;
}
.addimgfield-div input{
    font-size: 15px;
}
.profield-div label{
    float: left;
    text-align: left;
    /* margin-right: 5px; */
    width: 114px;
    padding-top: 5px;
    font-size: 15px;
}
.profield-div{
    height: auto;
    float: left;
    margin-top: 10px;
    width: auto;
    margin-right: 20px;
}
.no-wrap{
    white-space: nowrap;
}
.modal-popup{
    padding: 15px 5px;
    position: relative;
}
.closepopupqual{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.auto-complete-wrapper {
    padding: 0;
    background-color: #fff;
    width: 100%;
    position: relative;
    z-index: 1;
    box-shadow: 2px 2px 10px #c3c3c3;
    border-radius: 5px;
    overflow: hidden;
}
.nodata_found{
    text-align: center;
    border: 1px solid #ddd;
    padding: 20px 0px;
    color: red;
    font-size: 18px;
  }
  .saveButton.saveAllBannerEdit{
    font-size: 14px;
    background: #478800;
    padding: 5px 25px;
    border-radius: 20px;
    text-transform: capitalize;
    margin: 10px 10px;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
    color: #fff
  }

  .form-head-journal{
    font-size: 24px;
    font-weight: 600;
  }
  .form-section-brdr-jounal{
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 10px;
    margin: 20px 0;
  
  }
  .addrepwrapperborders {
    margin: 40px 0;
    position: relative;
    min-height: 400px;
    border-radius: 7px;
}
.input-design-journal{
    border: 1px solid #dcdcdc;
    background-color: #fff;
    width: 100%;
    padding: 3px 7px;
    resize: none;
    border-radius: 6px;
    color: #989898;
    margin-bottom: 15px;
  }
  .input-design-journal:focus{
    outline: unset;
  }
  .label-text-journal{
    font-size: 16px;
    font-weight: 600;
  }
  .star-red-label{
    color: red;
  }
  .fileUpload-medical-journal, .fileUpload-medical-journal:hover {
    position: relative;
    overflow: hidden;
    background: transparent;
    border: 1px solid var(--theme-color);
    text-align: center;
    padding: 5px 10px;
    border-radius: 8px;
    color: var(--theme-color);
    height: auto;
    width: auto;
    float: left;
    font-weight: 500;
    cursor: pointer;
    min-width: 140px;
    margin-top: 14px;
    font-size: 14px;
  }
  .fileUpload-medical-journal input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .fileUpload-img {
    margin-top: 14px;
  }
  .note-journlimg{
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
  }
  .detldes-div{
    padding: 10px 15px;
  }
  .formButton-save {
    color: var(--theme-color);
    padding: 5px 25px;
    border-radius: 10px;
    text-transform: capitalize;
    border: 1px solid var(--theme-color);
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
    font-weight: 600;
  }
  .formButton-cancel {
    color: red;
    padding: 5px 25px;
    border-radius: 10px;
    text-transform: capitalize;
    border: 1px solid red;
    margin: 10px 10px;
    background-color: #fff;
    text-decoration: none;
    font-size: 14px;
    min-width: 140px;
    font-weight: 600;
  
  }
  .formButton-divright{
    text-align: right;
  }
  .rdw-block-wrapper a,.rdw-dropdown-wrapper a{
    color:#000;
  }
  .ratingFlex {
    display: flex;
    justify-content: center;
}
.react-stars span {
    font-size: 30px !important;
}
.feedback-form {
    text-align: left;
    width: 500px;
    margin: 0 auto;
}
.poll-report-ul{
    padding-left: 0;
    counter-reset: my-sec-counter;
    margin-top: 30px;
}
.poll-report-li{
    margin-bottom: 10px;
    list-style: none;
    margin-bottom: 40px;
}
.poll-report-head{
    background-color: #fff;
    padding: 10px 0px;
    border-radius: 5px;
    font-family: "Poppins Medium";
    font-size: 16px;
    display: flex;
    /* border: 1px solid rgb(0 0 0 / 10%); */
}

/* .apexcharts-tooltip {
    background: red;
    color: orange;
  } */
  
  .arrow_box {
    position: relative;
    background: #555;
    border: 2px solid #000000;
    /* width: 300px; */
  }
  .arrow_box:after, .arrow_box:before {
    right: 50%;
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .arrow_box:after {
    border-color: rgba(85, 85, 85, 0);
    border-top-color: #555;
    border-width: 10px;
    margin-top: 0px;
    margin-right: 2px;
  }
  .arrow_box:before {
       border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 13px;
    margin-top: 0px;
  }
  
  #chart .apexcharts-tooltip {
    color: #fff;
    transform: translateX(10px) translateY(10px);
    overflow: visible !important;
    white-space: normal !important;
  }
  
  #chart .apexcharts-tooltip span {
    padding: 5px 10px;
    display: inline-block;
  }
  .arrow_box p{
    margin-bottom: 5px;
    font-size: 12px;
  }
  .poll-report-div{
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
  .poll-report-div:last-child{
    border-bottom: 0;
    padding-bottom: 0;
  }
  .poll-report-chart{
    width: 50%;
}
  .poll-report-view{
    width: 50%;
    padding-left: 50px;
  }
  .poll-report-view-head{
    font-family: "Poppins Medium";
    font-size: 13px;
    position: relative;
    margin-bottom: 5px;
    display: flex;
  }
  .poll-report-body{
    max-height: 100px;
    overflow: auto;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 10px 15px;
    border-radius: 5px;
    scrollbar-width: thin;
    scrollbar-color: #555 #f1f1f1;
    background-color: #eee;
    margin-left: 15px;
  }
  .poll-body-question {
    font-size: 14px;
    margin-bottom: 4px;
    font-family: "Poppins Medium";
  }
  .poll-body-text{
    font-size: 13px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-left: 15px;
  }
  .poll-report-body .poll-body-text{
    margin-left: 0;
  }
  .poll-body-text .float-right{
    max-width: 150px;
    flex: none;
  }
  .poll_close_div{
    position: absolute;
    top: 10px;
    right: 10px;
  }
.poll_close_div01{
    position: absolute;
    right: -10px;
    top: -20px;
}
.span_medium {
    margin-right: 5px;
    min-width: 150px;
    display: inline-block;
    position: relative;
  }
  .span_medium:after {
    position: absolute;
    content: ":";
    right: 5px;
    padding-right: 10px;
  }
.poll-report-head-span{
    font-family: 'Poppins Light';
    margin-left: 5px;
}
.poll-body-text:last-child{
    margin-bottom: 0;
}
.poll-report-body ::marker {
    font-size: 14px;
}
.checkbox-clr {
    width: 20px;
    height: 14px;
}
.poll_popup{
    position: unset;
    margin: 0;
    transform: unset;
    border: 1px solid #c3c3c3;
    box-shadow: 2px 2px 10px #c3c3c3;
  }
  .poll_popupdiv {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
}